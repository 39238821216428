<template>
  <div class="rule">
    <div
      class="rule__container col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="rule__content">
        <h2>{{ title }}</h2>
        <p>{{ sub }}</p>
      </div>
      <div class="rule__block d-flex">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="rule__block__item"
        >
          <img v-lazy="item.img" alt="rule" />
          <div class="rule__block__item--title">{{ item.title }}</div>
          <p style="white-space: pre-line" class="d-md-block d-none">
            {{ item.sub }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Rule',
  data() {
    return {
      ruleMobile: '/images/people/rul-mobile.png',
      title: this.$t('about_us').rule.title,
      sub: this.$t('about_us').rule.sub,
      cards: this.$t('about_us').rule.cards,
      data: [
        {
          img: '/images/aboutus/notify.png',
        },
        {
          img: '/images/aboutus/plain.png',
        },
        {
          img: '/images/aboutus/discussion.png',
        },
      ],
    };
  },
  mounted() {
    this.data = this.data.map((item, indx) => ({
      ...item,
      title: this.cards[indx].title,
      sub: this.cards[indx].sub,
    }));
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.title = this.$t('about_us').rule.title;
      this.sub = this.$t('about_us').rule.sub;
      this.cards = this.$t('about_us').rule.cards;
      this.data = this.data.map((item, indx) => ({
        ...item,
        title: this.cards[indx].title,
        sub: this.cards[indx].sub,
      }));
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
.rule {
  width: 100%;
  padding: 100px 0;
  background: url('/images/bg/bg-rule.png') rgb(255, 239, 230);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .rule__container {
    margin: auto;
    text-align: center;
  }
  .rule__content {
    text-align: center;
    max-width: 752px;
    margin: 20px auto;
    h2 {
      position: relative;
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      margin-bottom: 16px;
      z-index: 0;
    }
    p {
      color: var(--grey-text);
      font-size: 18px;
      margin: 0;
    }
  }
  .rule__block {
    position: relative;
    display: flex;
    width: 600px;
    height: 600px;
    margin: auto;
    margin-top: 60px;
    font-size: 18px;
  }
  .rule__block__item {
    position: absolute;
    width: 420px;
    height: 420px;
    border-radius: 50%;
    background: linear-gradient(180deg, #ffcab1 0%, #ffffff 100%);
    box-shadow: rgb(234 180 86 / 25%) 0px 2px 5px -1px,
      rgb(214 155 69 / 51%) 0px 1px 3px -1px;
    padding: 50px;
    & > img {
      width: 160px;
      height: auto;
    }
  }
  .rule__block__item:first-child {
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .rule__block__item:nth-child(2) {
    padding-top: 40px;
    top: 50%;
    left: -30%;
    & > img {
      width: 130px;
      margin-bottom: 8px;
    }
  }
  .rule__block__item:last-child {
    top: 50%;
    right: -30%;
    & > img {
      width: 180px;
      margin-bottom: 8px;
    }
  }
  .rule__block__item--title {
    font-weight: 700;
    font-size: 32px;
    color: var(--blue-text);
    &::first-letter {
      color: var(--orange);
    }
  }
  @media (max-width: 1024px) {
    padding: 50px 0 100px;
    .rule__block {
      width: 500px;
      height: 750px;
    }
  }
  @media (max-width: 768px) {
    .rule__block {
      width: 400px;
      height: 500px;
    }
    .rule__block__item {
      width: 300px;
      height: 300px;
    }
  }
  @media (max-width: 576px) {
    .rule__block {
      width: 200px;
      height: 300px;
    }
    .rule__block__item {
      padding: 0 !important;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .rule__block__item--title {
      font-size: 20px;
    }
    .rule__block__item:first-child {
      & > img {
        width: 80px;
      }
    }
    .rule__block__item:nth-child(2) {
      & > img {
        width: 60px;
      }
    }
    .rule__block__item:last-child {
      & > img {
        width: 80px;
      }
    }
  }
}
</style>
